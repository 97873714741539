import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

let topbar = document.querySelector('.topbar');
let topbarContainer = document.querySelector('.topbar__container')
let nav = document.querySelector('.nav');
let hamburger = document.querySelector('.hamburger');

const mqM = window.matchMedia('(min-width: 768px)');

function setResponsiveClass() {
    if (mqM.matches) {
        topbar.classList.add('topbar_desktop');
        topbar.classList.remove('topbar_mobile');
    } else {
        topbar.classList.remove('topbar_desktop');
        topbar.classList.add('topbar_mobile');
    }
}

setResponsiveClass();

let drawer = document.createElement('div');

function moveNav() {
    if (mqM.matches) {
        if (nav.parentElement != topbarContainer) {
            topbarContainer.appendChild(nav);
        }
        if (drawer != undefined) {
            drawer.remove();
            hamburger.classList.remove('is-active');
        }
    } else {
        drawer.classList.add('drawer');
        document.body.appendChild(drawer);
        drawer.appendChild(nav);

        hamburger.classList.remove('is-active');

        // Applico lo stile al drawer
        let topbarH = topbar.clientHeight;
        drawer.style.height = 'calc(100% - ' + topbarH + 'px)';
        drawer.style.top = topbarH + 'px';
        drawer.style.transform = 'translateX(101%)';
    }
}

moveNav();

function openCloseMenu() {
    let drawerStyle = getComputedStyle(drawer);
    let drawerDuration = drawerStyle.transitionDuration;
    let drawerDurationToMilliseconds = drawerDuration.replace(/s/g, '') * 1000;
    
    if (hamburger.classList.contains('is-active')) {
        drawer.style.transform = 'translateX(0%)';
        drawer.style.transitionProperty = 'transform';
        disableBodyScroll(drawer);
        
        setTimeout(() => {
            drawer.style.transitionProperty = '';
        }, drawerDurationToMilliseconds);
    
    } else {
        drawer.style.transform = 'translateX(101%)';
        drawer.style.transitionProperty = 'transform';
        
        setTimeout(() => {
            drawer.style.transitionProperty = '';
            clearAllBodyScrollLocks();
        }, drawerDurationToMilliseconds);
    }
}

// Clicca il pulsante
hamburger.addEventListener('click', () => {
    hamburger.classList.toggle('is-active');
    openCloseMenu();
});

// Funzioni da eseguire al resize
var resizeTimer;
window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
        moveNav();
        setResponsiveClass();
    }, 250);
});