import { Swiper, Thumbs, Navigation } from 'swiper';

let lavori = document.querySelectorAll('.lavoro');

Swiper.use([Thumbs, Navigation]);

lavori.forEach((lavoro) => {
    let galleriaThumb = lavoro.querySelector('.lavoro__thumbs');
    let galleria = lavoro.querySelector('.lavoro__gallery');

    let buttonNext = lavoro.querySelector('.swiper-button-next.lavoro__arrow_forGallery');
    let buttonPrev = lavoro.querySelector('.swiper-button-prev.lavoro__arrow_forGallery');

    let buttonNextThumb = lavoro.querySelector('.swiper-button-next.lavoro__arrow_forThumbs');
    let buttonPrevThumb = lavoro.querySelector('.swiper-button-prev.lavoro__arrow_forThumbs');
    
    let swiperThumbs = new Swiper(galleriaThumb, {
        spaceBetween: 16,
        slidesPerView: 3,
        loop: false,
        loopedSlides: 3,
        slidesPerView: 3,
        watchSlidesVisibility: true,

        navigation: {
            nextEl: buttonNextThumb,
            prevEl: buttonPrevThumb,
        },
    });

    let swiperGallery = new Swiper(galleria, {
        loop: false,
        loopedSlides: 3,
        thumbs: {
            swiper: swiperThumbs,
        },
        navigation: {
            nextEl: buttonNext,
            prevEl: buttonPrev,
        },
    });
});
