let what = document.querySelectorAll('.what');

function setStripe() {
    what.forEach((element) => {
        let stripe = element.querySelector('.what__stripe');
        let content = element.querySelector('.what__content');

        let contentH = content.clientHeight;
        stripe.style.height = contentH + 'px';
    });
}

setStripe();

var resizeTimer;
window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
        setStripe();
    }, 250);
});
